import { mapGetters, mapActions } from 'vuex'

const moduleName = 'customers'

export default {
  computed: {
    ...mapGetters(moduleName, {
      returnRoute: 'returnRoute',
      snapshots: 'snapshots',
      baseSnapshot: 'baseSnapshot',
      currentSnapshot: 'currentSnapshot',
      snapshotDiff: 'snapshotDiff'
    })
  },
  methods: {
    ...mapActions(moduleName, ['setReturnRoute', 'getStoreItem', 'editStoreItem', 'addStoreItem', 'saveSnapshot', 'clearSnapshots']),
    ...mapActions('quickinvoices', {setQuickInvoiceAsset: 'setAsset'}),
    ...mapActions('vehicles', {setVehicleCustomer: 'setCustomer'}),
    ...mapActions('printpreview', { setPrintPreviewReturnRoute: 'setReturnRoute', addEmailer: 'addEmailer', removeEmailer: 'removeEmailer' }),
    ...mapActions('printpreview', { addReportParameters: 'addParameters', removeReportParameters: 'removeParameters' })
  }
}
