<template>
  <div>
    <app-detail-header
      :show-toggle="true"
      :show-delete="false"
      :show-save="false"
      :show-cancel="false"
      @appDetailHeaderButtonClicked="onHeaderButtonClicked" />
    <div class="columns is-gapless">
      <div class="column is-narrow">
        <customer-list-side-menu
          v-model="filter"
          @filter="filterList"
          @reset="resetList" />
      </div>
      <div class="column">
        <div class="tile is-parent animated">
          <article class="tile is-child box detail-page-tile">
            <bulma-table
              class="table is-bordered is-striped is-narrow is-fullwidth"
              :columns="columns"
              :page-index="filter.pageIndex"
              :page-size="filter.pageSize"
              :total-rows="totalRows"
              :is-loading="isTableLoading"
              :sort-column="filter.sortColumn"
              :sort-order="filter.sortOrder"
              @pageChanged="onPageChange"
              @sort="sort">
              <tr
                v-for="(c, idx) in entitySummaries"
                :key="c.customerId"
                :class="{ 'is-selected' : selectedRow === idx }"
                @click="highlightSelected(idx, $event)">
                <td>
                  <router-link
                    :to="{ path: c.customerId }"
                    append>{{ c.fullName }}</router-link>
                </td>
                <td class="is-address">{{ c.streetName }}</td>
                <td>{{ c.suburb }}</td>
                <td>{{ c.defaultPhone }}</td>
                <td class="is-email">
                  <div>{{ c.defaultEmail }}</div>
                </td>
                <td>{{ c.defaultContactName }}</td>
                <td class="has-text-centered">
                  <span
                    class="icon"
                    :class="[ c.active ? 'has-text-success' : 'has-text-danger' ]">
                    <i
                      class="mdi mdi-24px"
                      :class="[ c.active ? 'mdi-check' : 'mdi-close' ]" />
                  </span>
                </td>
                <td class="has-text-centered">
                  <router-link
                    class="button is-primary is-small is-inverted tooltip"
                    :to="{ path: c.customerId }"
                    data-tooltip="Click to edit"
                    append>
                    <span class="icon is-medium">
                      <i class="mdi mdi-lead-pencil mdi-24px" />
                    </span>
                  </router-link>
                </td>
              </tr>
              <template slot="empty">
                <section class="section">
                  <div class="content has-text-grey has-text-centered">
                    <span icon="icon is-large">
                      <i class="mdi mdi-48px mdi-emoticon-sad" />
                    </span>
                    <p>Nothing</p>
                  </div>
                </section>
              </template>
            </bulma-table>
          </article>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppDetailHeader from '@/components/AppDetailHeader'
import CustomerListSideMenu from './CustomerListSideMenu'
import CustomerService from './CustomerService'
import CustomerRoutes from '@/views/customer/route-types'
import EventBus from '@/components/EventBus'
import { AppHeaderButtonTypes } from '@/enums'
import StoreMixin from './storeMixin'
import BulmaTable from '@/components/BulmaTable'
import Columns from './columns'
import PrintPreviewRoutes from '@/components/printpreview/route-types'
import { KeyValuePairModel } from '@/classes/viewmodels'
import _transform from 'lodash/transform'
import { Emailer } from '@/classes'

export default {
  name: 'CustomerListView',
  components: {
    AppDetailHeader,
    CustomerListSideMenu,
    BulmaTable
  },
  mixins: [StoreMixin],
  props: {
    pageSize: {
      type: Number,
      default: 18
    }
  },
  data() {
    return {
      entitySummaries: [],
      totalRows: 0,
      selectedRow: null,
      filter: {
        fullName: '',
        street: '',
        suburb: '',
        active: 1,
        sortColumn: Columns[0].name,
        sortOrder: Columns[0].defaultOrder,
        pageSize: this.pageSize,
        pageIndex: 1
      },
      isTableLoading: false
    }
  },
  computed: {
    CustomerRoutes: function() {
      return CustomerRoutes
    },
    filterKey() {
      if (this.$userInfo) {
        return `${this.$userInfo.sessionId}|${this.$route.meta.fkey}`
      } else {
        return ''
      }
    },
    columns() {
      return Columns
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    onHeaderButtonClicked(action) {
      switch (action) {
        case AppHeaderButtonTypes.AddNew:
          // this.addNewEntity()
          this.addStoreItem()
          break
        case AppHeaderButtonTypes.Print:
          this.print()
          break
        case AppHeaderButtonTypes.Delete:
          break
        case AppHeaderButtonTypes.Save:
          break
        case AppHeaderButtonTypes.Cancel:
          break
      }
    },
    async getData() {
      // this.$showSpinner()
      try {
        this.isTableLoading = true
        this.retrieveFilter()
        this.entitySummaries = await CustomerService.getEntitySummaries(this.filter)
        this.totalRows = this.entitySummaries.length > 0 ? this.entitySummaries[0].totalRows : 0
      } catch (e) {
        EventBus.$emit('serviceError', e.response.status)
        this.$hideSpinner()
        this.$notification.openMessageXhrError('', e)
      } finally {
        this.isTableLoading = false
        // this.$hideSpinner()
      }
    },
    persistFilter() {
      sessionStorage.setItem(this.filterKey, JSON.stringify(this.filter))
    },
    retrieveFilter() {
      const filter = JSON.parse(sessionStorage.getItem(this.filterKey))
      if (filter) {
        this.filter = filter
      }
    },
    onPageChange(pageIndex) {
      this.filter.pageIndex = pageIndex
      // sessionStorage.setItem(this.$route.meta.fkey, JSON.stringify(this.filter))
      this.persistFilter()
      this.getData()
    },
    filterList() {
      this.filter.pageIndex = 1
      // sessionStorage.setItem(this.$route.meta.fkey, JSON.stringify(this.filter))
      this.persistFilter()
      this.getData()
    },
    sort(name, order) {
      this.filter.sortColumn = name
      this.filter.sortOrder = order
      this.persistFilter()
      this.getData()
    },
    resetList() {
      this.filter = {
        active: 1,
        fullName: '',
        street: '',
        suburb: '',
        sortColumn: Columns[0].name,
        sortOrder: Columns[0].defaultOrder,
        pageSize: this.pageSize,
        pageIndex: 1
      }
      // sessionStorage.setItem(this.$route.meta.fkey, JSON.stringify(this.filter))
      this.persistFilter()
      this.getData()
    },
    print() {
      let params = Object.assign(
        {
          companyId: this.$userInfo.companyId
        },
        this.filter
      )
      params.sortColumn = this.filter.sortColumn.toUpperCase()
      params.pageIndex = 1
      params.pageSize = this.totalRows
      params = _transform(params, function(result, value, key) {
        result[key.toLowerCase()] = value
      })

      const keyValuePairs = KeyValuePairModel.convertToKeyValuePairs(params)
      this.addReportParameters(keyValuePairs)

      const emailer = new Emailer()
      emailer.subject = 'Customer List Report'
      emailer.reportName = 'rptCustomerList'
      this.addEmailer(emailer)

      this.$router.push({
        name: PrintPreviewRoutes.PrintPreview.name,
        params: { reportName: this.$route.meta.report },
        query: { parameterId: this.$guid.newGuid(), emailerId: emailer.id }
      })
    },
    highlightSelected(index, event) {
      this.selectedRow = index
    },
    async addNew() {
      this.addNewEntity()
    }
  }
}
</script>
